const RENOTIFY_PERIOD = 1000 * 60 * 60 * 24

// eslint-disable-next-line import/prefer-default-export
export function selectTermsAccepted(state) {
  const termsAccepted = state.termsAcceptedDate !== null
  return {
    termsAccepted,
    termsAcceptedRecently: termsAccepted && Date.now() - state.termsAcceptedDate < RENOTIFY_PERIOD,
  }
}
