export default [
  {
    title: 'State Street Global Advisors Launches Three ‘Innovative Industry’ ETFs',
    source: 'BusinessWire',
    link:
      'https://www.businesswire.com/news/home/20180103005728/en/State-Street-Global-Advisors-Launches-%E2%80%98Innovative-Industry%E2%80%99',
    date: new Date(2018, 0, 3),
  },
  {
    title: 'Artificial Intelligence and Industry Classifications',
    source: 'Nanalyze',
    link: 'https://www.nanalyze.com/2017/11/artificial-intelligence-industry-classifications/',
    date: new Date(2017, 10, 20),
  },
  {
    title: 'Kensho Technologies – Creating Stock Indices Using AI',
    source: 'Nanalyze',
    link: 'http://www.nanalyze.com/2017/04/kensho-technologies-artificial-intelligence-indices/',
    date: new Date(2017, 3, 5),
  },
  {
    title:
      'Intelligent Infrastructure Index Emphasizes The Technology Side Of Connected Infrastructure Projects',
    source: 'Benzinga',
    link:
      'https://www.benzinga.com/etfs/specialty-etfs/17/02/9046591/intelligent-infrastructure-index-emphasizes-the-technology-side-of',
    date: new Date(2017, 1, 15),
  },
  {
    title: 'Sygnia Launches the 4th Industrial Revolution Global Equity Fund',
    source: 'CNBC Africa',
    link:
      'https://www.sygnia.co.za/our-company/news-insights/details/2016/11/25/sygnia-launches-the-4th-industrial-revolution-global-equity-fund',
    date: new Date(2016, 10, 25),
  },
  {
    title: "Understanding the 'New Economy' with Kensho's New Economy Indices",
    source: 'Top Nasdaq Stocks',
    link:
      'http://topnasdaqstocks.com/index.php/2016/08/10/understanding-the-new-economy-with-kenshos-new-economy-indices',
    date: new Date(2016, 7, 10),
  },
  {
    title: 'Introducing the Kensho New Economy Indices',
    source: 'CNBC',
    link: 'http://www.cnbc.com/2016/08/08/introducing-the-kensho-new-economy-indices.html',
    date: new Date(2016, 7, 8),
  },
  {
    title: 'Kensho New Economies',
    source: 'Institutional Investor',
    link:
      'http://www.institutionalinvestor.com/banking-and-capital-markets/kensho-new-economies.html',
    date: new Date(2016, 6, 27),
  },
  {
    title: 'Financial-market index-makers are growing in power',
    source: 'The Economist',
    link:
      'https://www.economist.com/news/finance-and-economics/21727090-their-role-facing-greater-scrutiny-result-financial-market-index-makers-are',
    date: new Date(2017, 7, 24),
  },
]
