import PropTypes from 'prop-types'
import * as React from 'react'

import cs from './styles.css'

export default function Member(props) {
  const {children, image, name, title} = props
  return (
    <div className={cs.member}>
      <img src={image} alt={name} className={cs.photo} />
      <div>
        <h3 className={cs.name}>{name}</h3>
        <h2 className={cs.title}>{title}</h2>
        <p className={cs.about}>{children}</p>
      </div>
    </div>
  )
}

Member.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
