import * as React from 'react'

import Page from '../../components/Page'

import ArticlesSection from './ArticlesSection'
import VideosSection from './VideosSection'
import WhitepapersSection from './WhitepapersSection'
import cs from './styles.css'

export default function PressPage() {
  return (
    <Page>
      <section>
        <h5>In the Press</h5>
        <div className={cs.allPress}>
          <WhitepapersSection />
          <ArticlesSection />
          <VideosSection />
        </div>
      </section>
    </Page>
  )
}
