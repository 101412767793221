import cx from 'classnames'
import * as React from 'react'
import {Link} from 'react-router-dom'
import {IconHamburgerMenu} from '@kensho/icons'

import logo from '../../assets/images/logo.png'
import {navigation} from '../../constants'

import {renderMobileNavItem} from './utils'
import cs from './styles.css'

export default class MobileHeader extends React.Component {
  state = {
    menuOpen: false,
  }

  toggleMenu = () => this.setState(prevState => ({menuOpen: !prevState.menuOpen}))

  render() {
    const {menuOpen} = this.state
    return (
      <header className={cx(cs.header, cs.mobileHeader)}>
        <div className={cs.topNav}>
          <Link to="/">
            <img src={logo} alt="Kensho" className={cs.logo} />
          </Link>
          <IconHamburgerMenu size={33} className={cs.toggle} onClick={this.toggleMenu} />
        </div>
        <div className={cx(cs.menu, !menuOpen && cs.closed)} onClick={this.toggleMenu}>
          {navigation.map(renderMobileNavItem)}
        </div>
      </header>
    )
  }
}
