import PropTypes from 'prop-types'
import * as React from 'react'

import ExternalLink from '../../components/ExternalLink'

import cs from './styles.css'

export default function DataProduct(props) {
  const {children, image, name, paper} = props
  return (
    <div className={cs.product}>
      <ExternalLink to={paper}>
        <img className={cs.image} src={image} alt={name} />
      </ExternalLink>
      <div>
        <h1 className={cs.name}>{name}</h1>
        <p>{children}</p>
      </div>
    </div>
  )
}

DataProduct.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  paper: PropTypes.string.isRequired,
}
