import PropTypes from 'prop-types'

// eslint-disable-next-line import/prefer-default-export
export const index = PropTypes.shape({
  ccy: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  indexType: PropTypes.string,
  liveDate: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  rules: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      name: PropTypes.string,
      year: PropTypes.number,
    })
  ),
  spLink: PropTypes.string,
  symbol: PropTypes.string,
  symbolGtr: PropTypes.string,
})
