import {keyBy} from 'lodash'

import kfinp from '../../assets/wheel/0-kfinp.svg'
import kcnnectp from '../../assets/wheel/1-kcnnectp.svg'
import ksecurep from '../../assets/wheel/2-ksecurep.svg'
import kexplorp from '../../assets/wheel/3-kexplorp.svg'
import kmovep from '../../assets/wheel/4-kmovep.svg'
import kinfrap from '../../assets/wheel/5-kinfrap.svg'
import kpowerp from '../../assets/wheel/6-kpowerp.svg'
import kadvfabp from '../../assets/wheel/7-kadvfabp.svg'
import kevolvep from '../../assets/wheel/8-kevolvep.svg'
import kstaplesp from '../../assets/wheel/10-kstaplesp.svg'
import kondemandp from '../../assets/wheel/11-kondemandp.svg'
import {INDICES} from '../../constants'

const indices = keyBy(INDICES, 'symbol')

// wheel is in counter-clockwise order, starting from 3 o clock

export default [
  {
    image: kfinp,
    name: 'Democratized Banking',
    description: `
      Rapidly advancing technologies, evolving customer expectations, and a changing regulatory
      landscape are paving the way for disruptive innovation in Financial Services. The seeds for
      the democratization of this sector have already been sown in the guise of new technologies and
      business models, such as block chain technology, peer-to-peer lending, and roboadvisors. The
      Democratized Banking New Economy Sector tracks the companies and ecosystems at the forefront
      of innovation in this area, including companies focused on the future of payments, alternative
      lending and capital raising, and investment management.
    `,
    relatedIndices: [indices.KALTFINP, indices.KPAYP, indices.KLEDGERP],
  },
  {
    image: kcnnectp,
    name: 'Future Communication',
    description: `
      The past decade has initiated a siesmic shift in the way we communicate with each other
      through the increasing dominance of social media, texting, and IP video, but this is merely
      the start of a fundamental change in the way we interact with the world around us. The Future
      Communication New Economy Sector represents the companies and ecosystems driving these
      changes, including those at the forefront of the developments in communication tools,
      social media, and virtual, merged, or augmented reality.
    `,
    relatedIndices: [indices.KSOCIALP, indices.KTEAMP, indices.KVRP],
  },
  {
    image: ksecurep,
    name: 'Future Security',
    description: `
      The future of warfare and a nation's security will increasingly focus on cyber-related
      activities, autonomous military robots on land, sea, and in the air, as well as expanding to
      space. Thus, the wars over the next century will likely be fought as much with bits and bytes
      as via traditional means. This New Economy Sector collects companies that are involved in
      creating cutting-edge technology for offensive and defensive capabilities.
    `,
    relatedIndices: [
      indices.KBORGP,
      indices.KBOTSP,
      indices.KCYBERP,
      indices.KDMZP,
      indices.KDRONEP,
      indices.KMARSP,
      indices.KVRP,
    ],
  },
  {
    image: kexplorp,
    name: 'Final Frontiers',
    description: `
      Since the early beginnings of humanity, we have always been driving by a desire to boldly
      expand our horizons; exploration, particularly in parts completely unknown, captures the
      imagination like nothing else. The Final Frontiers New Economy sector, in turn, captures the
      companies and ecosystems at the forefront of the final frontiers of deep space and deep sea.
    `,
    relatedIndices: [indices.KMARSP, indices.KDRONEP],
  },
  {
    image: kmovep,
    name: 'Smart Transportation',
    description: `
      The Smart Transportation New Economy Sector captures the step changes currently underway in
      how people and goods will be transported in the near future. As fully autonomous vehicles
      start to become a reality, drones make rapid progress both technically and with legislative
      bodies around the world, and the future of train travel goes from the drawing board to the
      proof of concept stage, this is an area which will greatly impact almost every facet of our
      lives.
    `,
    relatedIndices: [indices.KATSP, indices.KCARSP, indices.KDRONEP, indices.KEVP],
  },
  {
    image: kinfrap,
    name: 'Intelligent Infrastructure',
    description: `
      While attention-grabbing headlines often focus on the more consumer oriented developments
      within the Fourth Industrial Revolution; however, the transition to an intelligent, adaptive,
      and connected infrastructure continues apace. The New Economies Intelligent Infrastructure
      Sector captures the full range of developments from smart buildings, smart road networks, to
      smart grids using sensors and intelligent meters to manage power lines and optimize power
      consumption.
    `,
    relatedIndices: [indices.KGRIDSP, indices.KHOMEP],
  },
  {
    image: kpowerp,
    name: 'Clean Power',
    description: `
      The Kensho Clean Power New Economy Sector is composed of all the companies involved in the
      business of producing clean power, from those involved in developing the underlying
      technologies through to the generation itself. It currently incorporates all commercial forms
      of clean power, such as solar, wind, hydroelectric, and geothermal.
    `,
    relatedIndices: [indices.KENERGYP, indices.KCLEANP],
  },
  {
    image: kadvfabp,
    name: 'Advanced Fabrication',
    description: `
      Manufacturing is undergoing significant structural changes driven by both consumer demand for
      increased personalization and scale moving upstream. Advances in technology and changes in
      marketplace expectations have lowered the barrier to entry in an industry  where scale was
      once a virtual imperative. Demand for "smart" products, the understanding that value lies in
      the services provided, not just the product itself, and technologies, such as additive
      manufacturing, are changing the shape of this sector. The Advanced Fabrication New Economy
      Sector captures the companies driving these changes.
    `,
  },
  {
    image: kevolvep,
    name: 'Human Evolution',
    description: `
      While the convergence of man and machine, the point of Singularity when our intelligence and
      physical being will become increasingly nonbiological, is still some way off, developments in
      genetic engineering, robotics, and implantable technology mean that we are getting ever
      closer. The Human Evolution New Economy Sector captures the companies and ecosystems driving
      the innovation across all these fronts.
    `,
    relatedIndices: [
      indices.KBORGP,
      indices.KBOTSP,
      indices.KDDDPP,
      indices.KDNAP,
      indices.KNANOP,
      indices.KVRP,
    ],
  },
  null,
  {
    image: kstaplesp,
    name: 'Sustainable Staples',
    description: `
      Expanding populations and ever-increasing demand for natural resources are driving the need
      for a more sustainable future economy, one in which innovation helps to manage consumption and
      develop alternative sources for the natural resources we consume. As such, companies involved
      in water re-use, sourcing, and efficiency, as well as efficient farming technologies will
      become ever more important to society.
    `,
  },
  {
    image: kondemandp,
    name: 'On Demand Economy',
    description: `
      The On-Demand Economy is set to continue its phenomenal expansion across ever-increasing parts
      of the economy. Consumer expectation and demand for the immediate provision of goods and
      services is driving the growth in everything from cloud-based compute resources to same-day
      deliveries of fresh foods and other goods, facilitated by online and mobile platforms.
      The On-Demand New Economy Sector is composed of companies at the forefront of innovations in
      this space.
    `,
  },
]
