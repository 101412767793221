import PropTypes from 'prop-types'
import * as React from 'react'
import cx from 'classnames'

import cs from './styles.css'

export default class Dropdown extends React.Component {
  static propTypes = {
    topLink: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node,
  }

  state = {
    open: false,
  }

  render() {
    const {children, className, topLink} = this.props
    const {open} = this.state
    return (
      <div
        className={cx(cs.dropdownMenu, className)}
        onMouseEnter={() => this.setState({open: true})}
        onMouseLeave={() => this.setState({open: false})}
      >
        {topLink}
        {open && <div className={cs.arrow} />}
        {open && (
          <div className={cs.dropdown} onClick={() => this.setState({open: false})}>
            {children}
          </div>
        )}
      </div>
    )
  }
}
