import PropTypes from 'prop-types'
import * as React from 'react'
import {connect} from 'react-redux'
import {CSSTransition} from 'react-transition-group'

import {selectTermsAccepted} from '../../selectors'
import * as actions from '../../state'
import ExternalLink from '../ExternalLink'

import cs from './styles.css'

// time until banner fades automatically (in ms)
const AUTO_ACCEPT_DELAY = 20000

const termsOfUseLink = (
  <ExternalLink to="/termsofuse.html" className={cs.link}>
    Terms Of Use
  </ExternalLink>
)

function Fade(props) {
  return <CSSTransition classNames={cs} timeout={300} mountOnEnter unmountOnExit {...props} />
}

// Note: The termsAcceptedRecently check only occurs on page reload.
class TermsOfUseBanner extends React.Component {
  static propTypes = {
    termsAccepted: PropTypes.bool.isRequired,
    termsAcceptedRecently: PropTypes.bool.isRequired,
    acceptTerms: PropTypes.func.isRequired,
    rejectTerms: PropTypes.func.isRequired,
  }

  componentDidMount() {
    setTimeout(this.autoAccept, AUTO_ACCEPT_DELAY)
  }

  autoAccept = () => {
    const {acceptTerms, termsAccepted, termsAcceptedRecently} = this.props
    if (termsAccepted && !termsAcceptedRecently) acceptTerms()
  }

  render() {
    const {acceptTerms, rejectTerms, termsAccepted, termsAcceptedRecently} = this.props
    return (
      <Fade in={termsAccepted && !termsAcceptedRecently}>
        <div className={cs.termsOfUseBanner}>
          <div>
            You have previously agreed to this site’s {termsOfUseLink}. To acknowledge that you are
            still bound by these terms, please click “Accept” or simply continue to browse.
          </div>
          <button className={cs.button} onClick={acceptTerms} type="button">
            Accept
          </button>
          <button className={cs.button} onClick={rejectTerms} type="button">
            Decline
          </button>
        </div>
      </Fade>
    )
  }
}

export default connect(
  selectTermsAccepted,
  {
    acceptTerms: actions.acceptTerms,
    rejectTerms: actions.rejectTerms,
  }
)(TermsOfUseBanner)
