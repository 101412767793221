import * as React from 'react'

import necsImage from '../../assets/images/data-products-necs.jpg'
import necsPaper from '../../assets/whitepapers/innovation-patterns-ssrn.pdf'
import {SP_CONTACT_LINK} from '../../constants'
import ExternalLink from '../../components/ExternalLink'
import Page from '../../components/Page'

import DataProduct from './DataProduct'

export default function DataProductsPage() {
  return (
    <Page>
      <section>
        <h5>Data Products</h5>
        <p>
          The Kensho Financial Products Division is a global leader in differentiated classification
          and index data solutions that leverage Kensho’s proprietary natural language processing
          (NLP) platform to uncover relationships not captured by conventional systems.
        </p>
        <p>
          For more information, please <ExternalLink to={SP_CONTACT_LINK}>contact us</ExternalLink>.
        </p>
      </section>
      <section>
        <DataProduct
          name="New Economies Classification Standard℠ (NECS℠)"
          image={necsImage}
          paper={necsPaper}
        >
          Kensho developed the New Economy Classification Standard (NECS) as a basis for organizing
          companies involved in the Fourth Industrial Revolution into their 21st Century Sectors,
          after recognizing that current classification schemes are unfit-for-purpose. NECS is the
          perfect tool for researchers, journalists, and financial decision-makers, as it ensures a
          holistic view of the most cutting-edge companies that are driving innovation in the New
          Economy℠.
        </DataProduct>
      </section>
    </Page>
  )
}
