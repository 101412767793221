import cx from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'

import cs from './styles.css'

export default function Button(props) {
  const {className, label, onClick, primary} = props
  const classes = cx(cs.button, className, primary && cs.primary)
  return (
    <div className={classes} onClick={onClick}>
      {label}
    </div>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
}
