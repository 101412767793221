import PropTypes from 'prop-types'
import * as React from 'react'

export default function ExternalLink(props) {
  const {children, className, to} = props
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" className={className}>
      {children}
    </a>
  )
}

ExternalLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
}
