import PropTypes from 'prop-types'
import * as React from 'react'

import ErrorBoundary from './ErrorBoundary'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import TermsOfUseBanner from './TermsOfUseBanner'
import Header from './Header'

export default function Page(props) {
  const {children} = props
  return (
    <React.Fragment>
      <ScrollToTop />
      <Header />
      <ErrorBoundary>
        <main>{children}</main>
      </ErrorBoundary>
      <Footer />
      <TermsOfUseBanner />
    </React.Fragment>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
