const ACCEPT_TERMS = 'ACCEPT_TERMS'
const REJECT_TERMS = 'REJECT_TERMS'

export function acceptTerms() {
  return {type: ACCEPT_TERMS, date: Date.now()}
}

export function rejectTerms() {
  return {type: REJECT_TERMS}
}

const initialState = {
  termsAcceptedDate: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_TERMS:
      return {...state, termsAcceptedDate: action.date}
    case REJECT_TERMS:
      return {...state, termsAcceptedDate: null}
    default:
      return state
  }
}
