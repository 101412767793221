import * as React from 'react'
import {orderBy} from 'lodash'

import Button from '../../components/Button'
import ExternalLink from '../../components/ExternalLink'
import {toDateFull} from '../../format'

import articles from './articles'
import cs from './styles.css'

const INITIAL_ARTICLES = 6

export default class ArticlesSection extends React.Component {
  state = {
    expanded: false,
  }

  expand = () => this.setState({expanded: true})

  render() {
    const {expanded} = this.state
    const pressArticles = orderBy(articles, 'date', 'desc').slice(
      0,
      expanded ? undefined : INITIAL_ARTICLES
    )
    return (
      <div className={cs.container}>
        <h2 className={cs.subheader}>Articles</h2>
        <div className={cs.articles}>
          {pressArticles.map(article => (
            <div key={article.title} className={cs.article}>
              <ExternalLink to={article.link} className={cs.articleName}>
                <h3>{article.title}</h3>
              </ExternalLink>
              <h4>
                {article.source}, {toDateFull(article.date)}
              </h4>
            </div>
          ))}
        </div>
        {articles.length > INITIAL_ARTICLES && !expanded && (
          <div className={cs.btnWrapper}>
            <Button label="View More Articles" onClick={this.expand} />
          </div>
        )}
      </div>
    )
  }
}
