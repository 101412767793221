import {groupBy} from 'lodash'
import * as React from 'react'

import {INDEX_TYPES, INDICES} from '../../constants'

import IndexType from './IndexType'

export default function IndicesSection() {
  const indicesByType = groupBy(INDICES.filter(i => !i.hidden), 'indexType')
  return (
    <section>
      <IndexType
        title="Market Indices"
        subtitle="Tracking the companies driving the Fourth Industrial Revolution"
        indices={indicesByType[INDEX_TYPES.COMPOSITE]}
      />
      <IndexType title="Sector Indices" indices={indicesByType[INDEX_TYPES.SECTOR_AGGREGATE]} />
      <IndexType title="Subsector Indices" indices={indicesByType[INDEX_TYPES.SUBINDEX]} />
      <IndexType title="Global Indices" indices={indicesByType[INDEX_TYPES.GLOBAL]} />
    </section>
  )
}
