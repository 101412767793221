import * as React from 'react'

import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

export default function Header() {
  return (
    <div>
      <DesktopHeader />
      <MobileHeader />
    </div>
  )
}
