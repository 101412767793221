import * as React from 'react'
import {Link} from 'react-router-dom'

import newEconomies from '../assets/new-economies-small.svg'
import IndicesSection from '../components/IndicesSection'
import Page from '../components/Page'

// TODO: Browse shouldn't steal Overview's styles
import cs from './Overview/styles.css'

export default function BrowsePage() {
  return (
    <Page>
      <section>
        <img src={newEconomies} alt="New Economies" className={cs.newEconomies} />
        <p>
          The Kensho New Economy Indices: 21st Century Sectors&#8480; dynamically capture the
          companies that are propelling these industries. Comprehensive and objective, each index is
          a means to thoroughly understand the makeup of its underlying industry, the technological
          drivers, and the ecosystems supporting them.
        </p>
      </section>
      <IndicesSection />
      <section>
        <h2>Disclaimers</h2>
        <small>
          Kensho® is a registered trademark of Kensho Technologies, LLC. All rights reserved.
        </small>
        <small>
          Kensho is not an investment adviser and makes no representation or recommendation
          regarding investment in any fund or investment vehicle. Please read{' '}
          <Link to="/disclaimers">important disclaimers</Link>.
        </small>
      </section>
    </Page>
  )
}
