import PropTypes from 'prop-types'
import * as React from 'react'

import ExternalLink from '../ExternalLink'

import cs from './styles.css'

const YEAR = new Date().getFullYear()

function Office(props) {
  const {city, location, name} = props
  return (
    <div className={cs.office}>
      <h4>{name}</h4>
      <div>{location}</div>
      <div>{city}</div>
    </div>
  )
}

Office.propTypes = {
  city: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default function Footer() {
  return (
    <footer className={cs.footer}>
      <div className={cs.inner}>
        <div className={cs.info}>
          <nav>
            <ExternalLink to="https://kensho.com/privacy">Privacy Policy</ExternalLink>
            <ExternalLink to="https://kensho.com/terms">Terms of Use</ExternalLink>
          </nav>
          <nav>
            <ExternalLink to="mailto:info@kensho.com">Email</ExternalLink>
            <ExternalLink to="http://linkedin.com/company/kensh-technologies">
              LinkedIn
            </ExternalLink>
            <ExternalLink to="http://twitter.com/kensho">Twitter</ExternalLink>
          </nav>
          <div>Copyright &copy; {YEAR} Kensho Technologies, LLC.</div>
        </div>
        <Office name="Harvard Square" location="44 Brattle St" city="Cambridge, MA 02138" />
        <Office name="New York City" location="One World Trade Center" city="New York, NY 10006" />
        <Office name="Washington D.C." location="Tysons Corner" city="McLean, VA 22101" />
        <Office
          name="Los Angeles"
          location="17383 Sunset Boulevard"
          city="Pacific Palisades, CA 90272"
        />
      </div>
    </footer>
  )
}
