import PropTypes from 'prop-types'
import * as React from 'react'
import {Link} from 'react-router-dom'
import {find, flatten, zip} from 'lodash'

import * as CustomPropTypes from '../../CustomPropTypes'
import newEconomies from '../../assets/new-economies-small.svg'
import {toDate} from '../../format'
import {INDICES, SP_CONTACT_LINK} from '../../constants'
import ExternalLink from '../../components/ExternalLink'
import Page from '../../components/Page'

import cs from './styles.css'

function NotFound(props) {
  const {index} = props
  return (
    <Page>
      <h1>Invalid Index</h1>
      <p>Index {index} not found.</p>
    </Page>
  )
}

NotFound.propTypes = {
  index: PropTypes.string.isRequired,
}

function Source(props) {
  const {link, n, name, year} = props
  return (
    <a target="_blank" rel="noopener noreferrer" href={link} title={`"${name}" [${year}]`}>
      <sup>[{n}]</sup>
    </a>
  )
}

Source.propTypes = {
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  n: PropTypes.number.isRequired,
}

function Overview(props) {
  const {index} = props
  const text = index.description.split(/\[\d+]/g)
  // eslint-disable-next-line
  const sources = index.sources.map((source, i) => <Source key={i} n={i + 1} {...source} />)
  const description = flatten(zip(text, sources))
  return (
    <div className={cs.overview}>
      <div className={cs.image} style={{backgroundImage: `url(${index.image})`}} />
      <div className={cs.info}>
        <div>
          <h3>{index.symbol}</h3>
          {index.liveDate && (
            <div>
              <small>Index Live Date:</small>
              <div>{toDate(index.liveDate)}</div>
            </div>
          )}
        </div>
      </div>
      <div className={cs.text}>
        {description}
        <br />
        <br />
        {index.rules}
      </div>
    </div>
  )
}

Overview.propTypes = {
  index: CustomPropTypes.index.isRequired,
}

function Index(props) {
  const {index} = props
  return (
    <Page>
      <section>
        <img src={newEconomies} alt="New Economies" className={cs.newEconomies} />
        <h1>
          S&P Kensho {index.name}&#8480; Index ({index.ccy})
        </h1>
        <Overview index={index} />
        <div className={cs.spLink}>
          <p>
            For more information, including index data, methodology, factsheet, and announcements,
            please visit:
            <br />
            <ExternalLink to={index.spLink}>{index.spLink}</ExternalLink>
          </p>
        </div>
      </section>
      <section>
        <h2>Licensing</h2>
        <p>
          If you are interested in licensing the New Economy Indices for use, please{' '}
          <ExternalLink to={SP_CONTACT_LINK}>contact us</ExternalLink>.
        </p>
      </section>
      <section>
        <h2>Disclaimers</h2>
        <small>
          Kensho® is a registered trademark of Kensho Technologies, LLC. All rights reserved.
        </small>
        <small>
          Kensho is not an investment adviser and makes no representation or recommendation
          regarding investment in any fund or investment vehicle. Please read{' '}
          <Link to="/disclaimers">important disclaimers</Link>.
        </small>
      </section>
    </Page>
  )
}

Index.propTypes = {
  index: CustomPropTypes.index.isRequired,
}

export default function DetailsPage(props) {
  const {match} = props
  const index = find(INDICES.filter(i => !i.hidden), {symbol: match.params.index})
  return index ? <Index index={index} /> : <NotFound index={match.params.index} />
}

DetailsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      index: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
