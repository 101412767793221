import {orderBy} from 'lodash'
import * as React from 'react'

import ExternalLink from '../../components/ExternalLink'
import {toDateFull} from '../../format'
import {WHITEPAPERS} from '../../constants'

import cs from './styles.css'

export default function WhitepapersSection() {
  return (
    <div className={cs.container}>
      <h2 className={cs.subheader}>Whitepapers</h2>
      {orderBy(WHITEPAPERS, 'date', 'desc').map(whitepaper => (
        <div key={whitepaper.title}>
          <ExternalLink to={whitepaper.link} className={cs.articleName}>
            <h3>{whitepaper.title}</h3>
          </ExternalLink>
          <h4>
            {whitepaper.source}, {toDateFull(whitepaper.date)}
          </h4>
        </div>
      ))}
    </div>
  )
}
