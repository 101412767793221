import PropTypes from 'prop-types'
import * as React from 'react'

import Raven from '../../raven'

import cs from './styles.css'

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true})
    Raven.captureException(error, {extra: info})
  }

  render() {
    const {children} = this.props
    const {hasError} = this.state
    if (!hasError) return children
    return (
      <div className={cs.errorWrapper}>
        <p className={cs.errorMessage}>Oops, something went wrong.</p>
      </div>
    )
  }
}
