import * as React from 'react'
import {Link} from 'react-router-dom'

import methodology from '../../assets/methodology.svg'
import newEconomies from '../../assets/new-economies.svg'
import IndicesSection from '../../components/IndicesSection'
import NewEconomiesWheel from '../../components/NewEconomiesWheel'
import Page from '../../components/Page'

import cs from './styles.css'

export default function OverviewPage() {
  return (
    <Page>
      <section>
        <img alt="New Economies" src={newEconomies} className={cs.newEconomies} />
        <h1>What are the New Economies Indices?</h1>
        <p>
          Pathbreaking innovations are transforming the global economy, and emerging technologies
          are reshaping traditional industries and spawning new ones at a staggering rate. They
          represent the foundation of the Fourth Industrial Revolution; the essence of 21st Century
          Sectors.
        </p>
        <q>
          Today, we are at the beginning of a Fourth Industrial Revolution. Developments in
          genetics, artificial intelligence, robotics, nanotechnology, 3D printing and
          biotechnology, to name just a few, are all building on and amplifying one another. This
          will lay the foundation for a revolution more comprehensive and all-encompassing than
          anything we have ever seen.
        </q>
        <small className={cs.attribution}>- World Economic Forum (WEF) report [2016]</small>
        <p>
          Rapid developments in robotics, automation, and artificial intelligence, together with
          extreme connectedness and the availability of exponential processing power, have laid the
          foundation for seismic changes across all facets of our lives. The democratization of
          infrastructure, whether that be banking, computing resources, or even power generation,
          has further lowered the barrier to entry across many industries, acting both as a catalyst
          for innovation and a disruptive force to incumbents.
        </p>
        <h1>Kensho New Economies: 21st Century Sectors</h1>
        <p>
          The Kensho New Economy Indices: 21st Century Sectors℠ dynamically capture the companies
          that are propelling the Fourth Industrial Revolution. The family of New Economy indices
          distinguishes itself by taking the most comprehensive and objective view across all the
          industries that are driving economic growth now and in the future.
        </p>
        <p>
          The typical industry classification schemes used today are simply unable to capture the
          drivers of future economic growth, particularly the degree of interconnectedness and
          fluidity which are the hallmarks of the new economy. In contrast, the Kensho New Economies
          not only identify the leading companies in each industry, they also capture the entire
          ecosystems supporting them; essential for fully understanding where the economic impact
          and benefits lie.
        </p>
        <p>
          The family of Kensho New Economies consists of industry level indices, which may be
          referenced individually or treated as building blocks for the higher level, 21st Century
          Sectors.
        </p>
        <NewEconomiesWheel />
        <h1>Methodology: How are the indices constructed?</h1>
        <p>
          The Kensho New Economy indices are constructed using an entirely systematic, rules-based
          methodology. Leveraging Kensho’s proprietary natural language processing (NLP) platform,
          millions of pages of regulatory filings and other public information are analyzed to
          identify the companies involved in each of the New Economies. Proprietary algorithms
          determine the extent to which each company is involved in a given New Economy; those
          companies for which a New Economy is a principal components of its business strategy will
          be overweight in the index.
        </p>
        <img alt="Methodology" src={methodology} className={cs.methodology} />
      </section>
      <IndicesSection />
      <section>
        <h2>Disclaimers</h2>
        <small>
          Kensho® is a registered trademark of Kensho Technologies, LLC. All rights reserved.
        </small>
        <small>
          Kensho is not an investment adviser and makes no representation or recommendation
          regarding investment in any fund or investment vehicle. Please read{' '}
          <Link to="/disclaimers">important disclaimers</Link>.
        </small>
      </section>
    </Page>
  )
}
