import * as React from 'react'
import {Link} from 'react-router-dom'

import * as CustomPropTypes from '../../CustomPropTypes'

import cs from './styles.css'

export default function Card(props) {
  const {index} = props
  const {image, name, symbol} = index
  return (
    <Link to={`/index/${symbol}`} className={cs.card}>
      <div className={cs.image} style={{backgroundImage: `url(${image})`}} />
      <div className={cs.info}>
        {name} Index ({symbol})
      </div>
    </Link>
  )
}

Card.propTypes = {
  index: CustomPropTypes.index.isRequired,
}
