import PropTypes from 'prop-types'
import * as React from 'react'

import * as CustomPropTypes from '../../CustomPropTypes'

import Card from './Card'
import cs from './styles.css'

export default function IndexType(props) {
  const {indices, subtitle, title} = props
  return (
    <div className={cs.section}>
      <h1>{title}</h1>
      {subtitle && <p className={cs.compositeSubtitle}>{subtitle}</p>}
      <div className={cs.grid}>
        {indices.map(index => (
          <Card key={index.symbol} index={index} />
        ))}
      </div>
    </div>
  )
}

IndexType.propTypes = {
  indices: PropTypes.arrayOf(CustomPropTypes.index).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}
