import 'core-js/shim'
import * as React from 'react'
import {render} from 'react-dom'
import {createStore} from 'redux'
import persistState from 'redux-localstorage'
import 'normalize.css'

import reducer from './state'
import App from './App'
import './styles.css'

const store = createStore(reducer, undefined, persistState())

const rootElement = document.getElementById('root')

render(<App store={store} />, rootElement)

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', () => render(<App store={store} />, rootElement))
}
