export const API_URL = process.env.NODE_ENV === 'production' ? 'https://www.kensho.com/api' : '/api'

export const SP_CONTACT_LINK = 'https://spindices.com/contact-us/'

export const navigation = [
  {to: '/browse', label: 'Indices'},
  {to: '/data-products', label: 'Data Products'},
  {
    isGroup: true,
    link: {label: 'About'},
    sublinks: [{to: '/press', label: 'Press'}, {to: '/team', label: 'Leadership Team'}],
  },
  {to: SP_CONTACT_LINK, label: 'Contact', newTab: true},
  {to: 'https://kensho.com', label: 'Kensho.com', newTab: true},
]

export const INDEX_TYPES = {
  SECTOR_AGGREGATE: 'SECTOR_AGGREGATE',
  SUBINDEX: 'SUBINDEX',
  GLOBAL: 'GLOBAL',
  COMPOSITE: 'COMPOSITE',
}

// set the flag 'hidden: true' to prevent an index from displaying on the index grid or generating
// a detail page
export const INDICES = [
  {
    name: 'Final Frontiers',
    symbolGtr: 'KEXPLORE',
    liveDate: new Date(2016, 11, 27),
    image: require('./assets/images/kexplorp.jpg'),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    symbol: 'KEXPLORP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-final-frontiers-index-usd',
    description: `
      Exploration, particularly in parts completely unknown, captures the imagination like
      nothing else; the Final Frontiers New Economy sector, in turn, captures the companies
      and ecosystems at the forefront of exploration at the final frontiers of deep space
      and deep sea.
    `,
    rules: `
      The S&P Kensho Final Frontiers℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the Final Frontiers 21st Century Sector℠, and is comprised of
      those companies in the S&P Kensho Space℠ Index (KMARSP) and the deep sea exploration focused
      components of the S&P Kensho Drones℠ Index (KDRONEP). Only those companies with a market
      capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of
      the most recent index selection date are eligible to be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Intelligent Infrastructure',
    symbolGtr: 'KINFRA',
    liveDate: new Date(2016, 10, 21),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    image: require('./assets/images/kinfrap.jpg'),
    symbol: 'KINFRAP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-intelligent-infrastructure-index-usd',
    description: `
      News headlines often focus on the more consumer-oriented developments of the Fourth Industrial
      Revolution; however, the transition to an intelligent, adaptive, and connected infrastructure
      continues apace. The New Economies Intelligent Infrastructure sector captures the full range
      of developments from smart buildings and road networks to smart grids that use sensors and
      intelligent meters to manage power lines and optimize power consumption.
    `,
    rules: `
      The S&P Kensho Intelligent Infrastructure℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the Intelligent Infrastructure 21st Century
      Sector℠, and is comprised of those companies in the S&P Kensho Smart Buildings℠ Index
      (KHOMEP) and the S&P Kensho Smart Grids℠ Index (KGRIDSP). Only those companies with a market
      capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the
      most recent index selection date are eligible to be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Smart Transportation',
    symbolGtr: 'KMOVE',
    liveDate: new Date(2016, 11, 2),
    image: require('./assets/images/kmovep.jpg'),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    symbol: 'KMOVEP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-smart-transportation-index-usd',
    description: `
      The Smart Transportation New Economy sector focuses on the changes underway in how people and
      goods will be transported in the near future. As fully autonomous vehicles start to become a
      reality, drones make rapid progress both technically and with legislative bodies around the
      world, and the future of train travel goes from the drawing board to the proof of concept
      stage, this is an area which will greatly impact almost every facet of our lives.
    `,
    rules: `
      The S&P Kensho Smart Transportation℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the Smart Transportation 21st Century Sector℠, and
      is comprised of those companies in the S&P Kensho Autonomous Vehicles℠ Index (KCARSP), the
      S&P Kensho Electric Vehicles℠ Index (KEVP), the S&P Kensho Advanced Transport Systems℠ Index
      (KATSP), and the civilian/commercially focused components of the S&P Kensho Drones℠ Index
      (KDRONEP). Only those companies with a market capitalization of $100mm and a 3 month average
      daily value traded (ADVT) of $1mm as of the most recent index selection date are eligible to
      be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Clean Power',
    symbolGtr: 'KPOWER',
    liveDate: new Date(2016, 11, 1),
    image: require('./assets/images/kpowerp.jpg'),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    symbol: 'KPOWERP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-clean-power-index-usd',
    description: `
      Climate change and the world’s continuing reliance on fossil fuels has brought clean power to
      the forefront of the political stage. Significant progress has been made over the past few
      years to improve the efficiency and reduce the costs of the underlying technology involved in
      the generation of power from wind, solar, hydroelectric, and geothermal sources. 2013 was the
      first year in which new clean power capacity brought online during the year outpaced capacity
      from new plants burning fossil fuels[1]  this trend is set to continue accelerating going
      forward.
    `,
    rules: `
      The S&P Kensho Clean Power℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the Clean Power 21st Century Sector℠, and is comprised of those
      companies in the S&P Kensho Clean Energy℠ Index (KENERGYP) and the S&P Kensho Cleantech℠
      Index (KCLEANP). Only those companies with a market capitalization of $100mm and a 3 month
      average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Fossil Fuels Just Lost the Race Against Renewables',
        year: 2015,
        link:
          'https://www.bloomberg.com/news/articles/2015-04-14/fossil-fuels-just-lost-the-race-against-renewables',
      },
    ],
  },
  {
    name: 'Future Security',
    symbolGtr: 'KSECURE',
    liveDate: new Date(2017, 1, 14),
    image: require('./assets/images/ksecurep.jpg'),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    symbol: 'KSECUREP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-future-security-index-usd',
    description: `
      The nature of warfare and of broader security threats has fundamentally changed over the past
      few years. Terrorism and cyber security, each with no respect for traditional borders, have
      become the dominant threats in today’s world. Robust border security, whether in the physical
      or digital worlds, is paramount. When war is waged in the physical world, the instruments of
      war themselves have changed dramatically with increasing use of drones, robots, and other
      sophisticated weaponry.
    `,
    rules: `
      The S&P Kensho Future Security℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the Future Security 21st Century Sector℠, and is comprised of
      those companies in the S&P Kensho Cyber Security Index (KCYBERP) and the S&P Kensho Smart
      Borders (KDMZP) Index, and relevant components from the following indices: S&P Kensho Robotics
      Index (KBOTSP), S&P Kensho Drones Index (KDRONEP), S&P Kensho Space Index (KMARSP), S&P Kensho
      Wearables Index (KBORGP), and S&P Kensho Virtual Reality Index (KVRP). Only those companies
      with a market capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm
      as of the most recent index selection date are eligible to be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Space',
    symbolGtr: 'KMARS',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kmarsp.jpg'),
    symbol: 'KMARSP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-space-index-usd',
    description: `
      The so-called "Space Race" between the United States and the former Soviet Union during the
      Cold War was a boon for technological development and industry. Today, the space industry is
      driven by desire for commercialization and scientific exploration of the final frontier.
      Rising interest in space tourism, a growing demand for rare-earth minerals and even
      interplanetary colonization is propelling companies to expand the scope and availability of
      space travel and exploration into deep space. This is prompting demand for rockets, launch
      vehicles, satellites, and their subsystems, which together make Space a $330bn global
      industry.[1] NASA is predicting it will be able to send humans to an asteroid by 2025 and Mars
      in the 2030's.[2]
    `,
    rules: `
      The S&P Kensho Space℠ Index uses an entirely rules-based methodology to objectively uncover
      companies involved in the Space sector, and is composed of US-listed companies focused on
      space-related activities as a principal component of their business strategy as well as the
      supply-chain companies involved in the industry. Only those companies with a market
      capitalization of $300mm and a 3 month average daily value traded (ADVT) of $2mm as of the
      most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Space Foundation: The Space Report',
        year: 2015,
        link:
          'https://www.spacefoundation.org/sites/default/files/downloads/The_Space_Report_2015_Overview_TOC_Exhibits.pdf',
      },
      {
        name: "NASA's Journey to Mars",
        year: 2014,
        link: 'http://www.nasa.gov/content/nasas-journey-to-mars',
      },
    ],
  },
  {
    name: 'Drones',
    symbolGtr: 'KDRONE',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kdronep.jpg'),
    symbol: 'KDRONEP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-drones-index-usd',
    description: `
      Drones were initially popularized through military applications, but their most prevalent use
      in the New Economy will likely be recreational and commercial: the total global drone market
      is expected to exceed $100bn by 2020.[1] While a significant portion of the market for drones
      will come from hobbyist users, the increasing payload capacity and ranges of non-military
      drones means that commercial use will become increasingly widespread - for aerial imaging,
      repairs, shipments, and even enhanced provision of mobile data coverage. The companies
      involved in drone production encompass a wide range of players, from large conglomerates, to
      young specialists that make specialized components or complete drones.
    `,
    rules: `
      The S&P Kensho Drones℠ Index uses an entirely rules-based methodology to objectively uncover
      companies involved in the drone industry, and is composed of US-listed companies focused on
      drone-related activities as a principal component of their business strategy as well as the
      supply-chain companies involved in the industry. Only those companies with a market
      capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the
      most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Global Drone Spending To Exceed $100 Billion In Five Years, Says Goldman',
        year: 2016,
        link:
          'http://www.benzinga.com/analyst-ratings/analyst-color/16/03/7705103/global-drone-spending-to-exceed-100-billion-in-five-year',
      },
    ],
  },
  {
    name: 'Robotics',
    symbolGtr: 'KBOTS',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kbotsp.jpg'),
    symbol: 'KBOTSP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-robotics-index-usd',
    description: `
      Specialized robots have been part of manufacturing for decades, but the emergence of advanced
      sensing technologies alongside more sophisticated processing power is ushering in a new era of
      robotics that is reaching not only industrial segments of the economy, but also healthcare,
      consumer, and military applications. The global robotics industry currently enjoys a $25bn
      market size, but is projected to grow up to fivefold to $123bn by 2026.[1] For instance, it is
      estimated only 10% of manufacturing tasks are currently automated worldwide, with this likely
      to rise to 45% within 10 years.[2] The number and types of companies involved in the robotics
      industry is likewise expanding, and as the range of uses and prevalence of robots in society
      grows, there is a rising focus on developing both specialized components and complete robots.
    `,
    rules: `
      The S&P Kensho Robotics℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in developing robots for a wide variety of uses as well as the
      ecosystem of supply chain companies providing specialized products and services. Only those
      companies with a market capitalization of $100mm and a 3 month average daily value traded
      (ADVT) of $1mm as of the most recent index selection date are eligible to be included in the
      index.
    `,
    sources: [
      {
        name: 'Robotics 2016 - 2026: Players, Technologies, Markets',
        year: 2015,
        link: 'http://www.idtechex.com/research/reports/robotics-2016-2026-000458.asp',
      },
      {
        name: 'The Robotics Revolution: The Next Great Leap in Manufacturing',
        year: 2015,
        link:
          'https://www.bcgperspectives.com/content/articles/lean-manufacturing-innovation-robotics-revolution-next-great-leap-manufacturing/',
      },
    ],
  },
  {
    name: 'Cyber Security',
    symbolGtr: 'KCYBER',
    liveDate: new Date(2016, 2, 10),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kcyberp.jpg'),
    symbol: 'KCYBERP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-cyber-security-index-usd',
    description: `
      High profile cyber security breaches of business-critical computer systems are continuing
      apace, leaving business and individual confidential information vulnerable to theft.
      Cybercrime is estimated to cost US businesses $400B each year.[1] Gartner Inc estimates that
      the market for cybersecurity products and services was $75B in 2015,[2] and will increase to
      $120B in 2020.[3] The advent of the Internet of Things (IoT) has heralded a new era of
      interconnectedness which only serves to underscore the continuing need for cyber security.
    `,
    rules: `
      The S&P Kensho Cyber Security℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the emerging cybersecurity sector, and is composed of companies
      focused on cybersecurity-related activities as a principal component of their business
      strategy. Only those companies with a market capitalization of $300mm and a 3 month average
      daily value traded (ADVT) of $2mm as of the most recent index selection date are eligible to
      be included in the index.
    `,
    sources: [
      {
        name: 'Net Losses: Estimating the Global Cost of Cybercrime',
        year: 2014,
        link: 'http://www.mcafee.com/us/resources/reports/rp-economic-impact-cybercrime2.pdf',
      },
      {
        name: `Gartner Says Worldwide Information Security Spending Will Grow Almost 4.7 Percent to
          Reach $75.4 Billion in 2015`,
        year: 2015,
        link: 'http://www.gartner.com/newsroom/id/3135617',
      },
      {
        name: 'Cyber Security Market by Solution [...] Global Forecast to 2020',
        year: 2015,
        link: 'http://www.marketsandmarkets.com/Market-Reports/cyber-security-market-505.html',
      },
    ],
  },
  {
    name: 'Wearables',
    symbolGtr: 'KBORG',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kborgp.jpg'),
    symbol: 'KBORGP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-wearables-index-usd',
    description: `
      Wearable technologies are becoming pervasive today and include such technologies as
      smartwatches, wrist-worn fitness trackers, and wearable cameras, all of which give users more
      control over and insight into their own activities, as well as help them digitally connect
      with the world around them. Future wearables hold the promise of integrating and connecting us
      in ways never before thought possible - the next generation will include implantable
      technology and is already under active development. Crucially, present and future wearables
      are to be more portable than many of their precursor technologies, and integrate more
      seamlessly into users' daily lives and use patterns. The market is expected to expand from
      $10bn today to over $35bn by 2022.[1] Much of this growth is anticipated to come from an
      increase in the number of healthcare applications for wearables, and improved miniaturization
      and energy efficiency. A diverse range of companies produce components of wearable technology
      and finished products.
    `,
    rules: `
      The S&P Kensho Wearables℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in developing the components and finished products related to
      wearable technologies, including smartwatches, fitness trackers and wearable cameras. Only
      those companies with a market capitalization of $100mm and a 3 month average daily value
      traded (ADVT) of $1mm as of the most recent index selection date are eligible to be included
      in the index.
    `,
    sources: [
      {
        name: 'Wearable Technology - Global Market Outlook (2015-2022)',
        year: 2015,
        link: 'http://www.strategymrc.com/report/wearable-technology-market',
      },
    ],
  },
  {
    name: '3D Printing',
    symbolGtr: 'KDDDP',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kdddpp.jpg'),
    symbol: 'KDDDPP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-3d-printing-index-usd',
    description: `
      Only a few years ago, 3D printing technology was limited to the prototyping of small ad-hoc
      models that were more decorative than practical. Now, with improvements in both technology and
      materials, a 3D printer can produce an enormous range of outputs for the "on demand economy",
      from working individual components to entire finished products. 3D bioprinters can even create
      living human tissue. All industries have seen a huge uptake in usage: rapid prototyping; the
      generation of scale models; small-batch production of customized objects; and cost-effective,
      large-scale production of commoditized objects. 3D printing is expected to grow in size from
      an approximately $4 billion market today, to a $20 billion global market potential by 2020.[1]
      Companies involved in this industry either make 3D printers, or else produce specialized
      components, including the modelling software for 3D printers.
    `,
    rules: `
      The S&P Kensho 3D Printing℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the 3D printing industry, and is composed of US-listed companies
      focused on 3D-related activities as a principal component of their business strategy,
      including those that produce modelling software, as well as the supply-chain companies
      supporting them. Only those companies with a market capitalization of $100mm and a 3 month
      average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: `Value of the additive manufacturing (3D printing) market worldwide from 2013 to
          2020`,
        year: 2016,
        link: 'http://www.statista.com/statistics/261693/3d-printing-market-value-forecast/',
      },
    ],
  },
  {
    name: 'Autonomous Vehicles',
    symbolGtr: 'KCARS',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kcarsp.jpg'),
    symbol: 'KCARSP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-autonomous-vehicles-index-usd',
    description: `
      Transportation is a key enabler of the economy, and the global automotive industry itself
      generates US$5tn in annual output.[1] Companies involved in transporting people and goods are
      increasingly focused on introducing autonomous alternatives, those which can operate without
      human input using sensors, radars, cameras etc. Although fully self-driving vehicles are not
      yet in the mainstream, partially autonomous cars and trucks are already a reality today. Some
      of the main companies are well-known players in the automotive industry, but aspiring entrants
      continue to make significant inroads. The “connected cars” market could exceed $40bn in size
      by 2025,[2] and its growth will have far-reaching economic consequences, as it will transform
      how human and physical resources move about, and will impact productivity, location decisions,
      health, and even personal finances and vehicle ownership.
    `,
    rules: `
      The S&P Kensho Autonomous Vehicles℠ Index uses an entirely rules-based methodology to
      objectively uncover companies leading the way forward toward autonomous transportation,
      whether directly through the the development of autonomous vehicles themselves or as part of
      the ecosystem supporting these initiatives. Only those companies with a market capitalization
      of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the most recent index
      selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Robot Revolution  Global Robot & AI Primer',
        year: 2015,
        link:
          'http://www.bofaml.com/content/dam/boamlimages/documents/PDFs/robotics_and_ai_condensed_primer.pdf',
      },
      {
        name: 'Driverless-Car Global Market Seen Reaching $42 Billion by 2025',
        year: 2015,
        link:
          'http://www.bloomberg.com/news/articles/2015-01-08/driverless-car-global-market-seen-reaching-42-billion-by-2025',
      },
    ],
  },
  {
    name: 'Smart Buildings',
    symbolGtr: 'KHOME',
    liveDate: new Date(2016, 6, 1),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/khomep.jpg'),
    symbol: 'KHOMEP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-smart-buildings-index-usd',
    description: `
      Smart buildings provide people more control over, convenience from, and data on the structures
      in which they live, work, and play. For example, smart buildings can: more efficiently
      modulate energy usage; more securely restrict or monitor access; and provide remote or refined
      control of temperature, ventilation, and entertainment systems. These structures will
      intertwine with the New Economy by making the spaces in which people reside, learn, conduct
      business, and enjoy themselves more liveable, energy efficient, and cost effective. The smart
      building market is anticipated to reach a scale of nearly $40bn in size by 2020.[1]
    `,
    rules: `
      The S&P Kensho Smart Buildings℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the Smart Buildings / Connected Home industry, and is composed
      of US-listed companies focused on building these systems as a principal component of their
      business strategy, or that supply components, such as sensors, to these principal companies.
      Only those companies with a market capitalization of $100mm and a 3 month average daily value
      traded (ADVT) of $1mm as of the most recent index selection date are eligible to be included
      in the index.
    `,
    sources: [
      {
        name: 'Smart Building Market worth $36,398.7 Million by 2020',
        year: 2015,
        link: 'http://www.marketsandmarkets.com/PressReleases/smart-building.asp',
      },
    ],
  },
  {
    name: 'Nanotechnology',
    symbolGtr: 'KNANO',
    liveDate: new Date(2016, 6, 1),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/knanop.jpg'),
    symbol: 'KNANOP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-nanotechnology-index-usd',
    description: `
      Engineering at the molecular level is no easy feat, and nanotechnology will have an impact on
      both the electronics industry with ever more miniaturized circuits as well as biotechnical
      products that will become "programmable" and be able to carry out more intelligent tasks in
      the human body. Although many companies are still in early R&D stages, there are a number of
      players that have started to utilize nanotechnology in their day-to-day operations. This index
      ecompasses companies with mechanical nanoscale processes, from companies that actually produce
      nanoscale materials to those that use nanotechnology in their production or that build
      machines capable of measuring information at the nanoscale. The industry is predicted to grow
      rapidly over the coming years, with a compound annual growth rate topping 17.5% through
      2022.[1]
    `,
    rules: `
      The S&P Kensho Nanotechnology℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the nanotechnology industry, and is composed of US-listed
      companies focused on nano-related activities as a component of their business strategy. This
      includes companies that produce nanoscale materials, as well as those that use nanotechnology
      in their production lines or that build machines capable of measuring information at the
      nanoscale. Only those companies with a market capitalization of $100mm and a 3 month average
      daily value traded (ADVT) of $1mm as of the most recent index selection date are eligible to
      be included in the index.
    `,
    sources: [
      {
        name: 'Global Nanotechnology Market Outlook 2022',
        year: 2015,
        link: 'http://www.researchandmarkets.com/research/3bsn2g/global',
      },
    ],
  },
  {
    name: 'Virtual Reality',
    symbolGtr: 'KVR',
    liveDate: new Date(2016, 6, 1),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kvrp.jpg'),
    symbol: 'KVRP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-virtual-reality-index-usd',
    description: `
      After decades of anticipation, virtual reality is finally becoming a reality. Virtual reality
      (VR) utilizes a combination of computers, images, and sound to simulate interactive
      experiences for users within artificial environments. Augmented reality (AR), meanwhile, is
      technology that provides extra visual or sound content from real-world environments. There is
      an increasing ecosystem of companies involved with immersive video games, holographic
      communication, and virtual surgical platforms. The virtual reality market is expected to
      expand to $80bn by 2025.[1]
    `,
    rules: `
      The S&P Kensho Virtual Reality℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the virtual reality sector, composed of US-listed companies
      focused on virtual or augmented reality related activities as a principal component of their
      business strategy as well as the supply-chain companies involved in the industry. Only those
      companies with a market capitalization of $100mm and a 3 month average daily value traded
      (ADVT) of $1mm as of the most recent index selection date are eligible to be included in the
      index.
    `,
    sources: [
      {
        name: `Goldman Sachs Has Four Charts Showing the Huge Potential in Virtual and Augmented
          Reality`,
        year: 2016,
        link:
          'http://www.bloomberg.com/news/articles/2016-01-13/goldman-sachs-has-four-charts-showing-the-huge-potential-in-virtual-and-augmented-reality',
      },
    ],
  },
  {
    name: 'Genetic Engineering',
    symbolGtr: 'KDNA',
    liveDate: new Date(2016, 8, 1),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kdnap.jpg'),
    symbol: 'KDNAP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-genetic-engineering-index-usd',
    description: `
      Genetic engineering is the use of biotechnology to alter the genes of living cells in animals,
      plants, and people. Although today the genetic engineering industry accounts for less than 1%
      of the nearly $300 billion global biotech market, that figure is expected to more than double
      in the next half-decade. Within agriculture, genetically modified seed is already a dominant
      force having reached a global value of $16B in 2014.[1][2][3] With the human genome sequence
      now mapped out, this sector has the potential to become a dominant component of the
      biotechnology market as companies become more sophisticated in leveraging this technology.
      Genetic engineering offers enormous possibilities for breakthrough medical therapies and
      diagnostics, as well as producing more nutritious and resilient crops and livestock. The
      economic influence of genetic engineering, both directly and indirectly, will also be hugely
      significant once the products move into the mainstream.
    `,
    rules: `
      The S&P Kensho Genetic Engineering℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the genetic engineering industry, and is composed of
      US-listed companies focused on these activities as a principal component of their business
      strategy, or that provide tools to allow other companies to more efficiently execute their own
      research. Only those companies with a market capitalization of $300mm and a 3 month average
      daily value traded (ADVT) of $2mm as of the most recent index selection date are eligible to
      be included in the index.
    `,
    sources: [
      {
        name: `Biotechnology Market Analysis By Technology (Fermentation, Tissue Engineering, PCR
          Technology, Nanobiotechnology, Chromatography, DNA Sequencing, Cell-based Assay), By
          Application (Biopharmaceuticals, Bioservices, Bioagriculture, Bioindustry) And Segment
          Forecasts To 2020`,
        year: 2015,
        link: 'http://www.grandviewresearch.com/industry-analysis/biotechnology-market',
      },
      {
        name: `Genome Editing / Genome Engineering Market by Application (Cell Line Engineering,
          Animal & Plant Genetic Engineering), Technology (CRISPR, Antisense, TALEN, Zinc Finger
          Nuclease) & End User (Biotechnology & Pharmaceutical, CRO) - Global Forecast to 2019`,
        year: 2015,
        link:
          'http://www.marketsandmarkets.com/Market-Reports/genome-editing-engineering-market-231037000.html',
      },
      {
        name: 'Genetically Modified Crop Industry Continues to Expand - WorldWatch Institute',
        year: 2015,
        link: 'http://www.worldwatch.org/genetically-modified-crop-industry-continues-expand-0',
      },
    ],
  },
  {
    name: 'Cleantech',
    symbolGtr: 'KCLEAN',
    liveDate: new Date(2016, 7, 5),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kcleanp.jpg'),
    symbol: 'KCLEANP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-cleantech-index-usd',
    description: `
      Clean technology is the engine producing the technology that is powering the clean energy
      revolution (solar, wind, hydro, geothermal). Renewable energy in global power generation is
      expected to rise to over 26% by 2020 from 22% in 2013.[1] It is projected that renewable
      energy generation will surpass coal as the leading power generation source worldwide in
      2040.[2] There are numerous companies that are focused on producing the hardware and
      technology to extract this power more cheaply and efficiently. From better solar cells to
      lighter wind turbines, this sector will be integral to the continued uptake in clean and
      renewable energy.
    `,
    rules: `
      The S&P Kensho Cleantech℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the clean technology sector, and is composed of US-listed
      companies focused on renewable energy technology (solar, wind, hydro, geothermal) activities
      as a principal component of their business strategy as well as the supply chain companies
      involved in the industry. Only those companies with a market capitalization of $100mm and a 3
      month average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Renewables to lead world power market growth to 2020',
        year: 2015,
        link:
          'http://www.iea.org/newsroomandevents/pressreleases/2015/october/renewables-to-lead-world-power-market-growth-to-2020.html',
      },
      {
        name: 'INTERNATIONAL ENERGY OUTLOOK 2016',
        year: 2016,
        link: 'http://www.eia.gov/forecasts/ieo/electricity.cfm',
      },
    ],
  },
  {
    name: 'Clean Energy',
    symbolGtr: 'KENERGY',
    liveDate: new Date(2016, 7, 5),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kenergyp.jpg'),
    symbol: 'KENERGYP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-clean-energy-index-usd',
    description: `
      The S&P Kensho Clean Energy index includes companies that own and operate clean energy
      generating assets. In 2016 both Germany and Portugal had full days where their countries ran
      on renewable energy sources alone.[1]
    `,
    rules: `
      The S&P Kensho Clean Energy℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the clean energy sector, and is composed of US-listed companies
      focused on renewable energy operations (solar, wind, hydro, geothermal) activities as a
      principal component of their business strategy. Only those companies with a market
      capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of
      the most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Portugal runs for four days straight on renewable energy alone',
        year: 2016,
        link:
          'https://www.theguardian.com/environment/2016/may/18/portugal-runs-for-four-days-straight-on-renewable-energy-alone',
      },
    ],
  },
  {
    name: 'Smart Grids',
    symbolGtr: 'KGRIDS',
    liveDate: new Date(2016, 11, 7),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kgridsp.jpg'),
    symbol: 'KGRIDSP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-smart-grids-index-usd',
    description: `
      In the US alone, $975 billion is expected to be spent annually on infrastructure upgrades by
      2025 as the aging infrastructure is replaced[1]. At the same time, very significant changes
      are underway in the way we use manage and optimize our infrastructure assets and natural
      resources as well as the need to support emerging capabilities such as autonomous vehicles and
      distributed clean power generation. While traditional construction and engineering companies
      will likely benefit from this wave of investment, the focus will increasingly be on the
      companies producing the sophisticated technology and services required to introduce and manage
      a smart, connected, and adaptive infrastructure capable of managing our natural resources more
      effectively and supporting a broad array of emerging technologies.
    `,
    rules: `
      The S&P Kensho Smart Grids℠ Index uses an entirely rules-based methodology to objectively
      identify companies involved in the smart power, intelligent water, and intelligent
      transportation systems industries, and is composed of US-listed companies focused on related
      activities as a principal component of their business strategy as well as the supply-chain
      companies involved in the industry. Only those companies with a market capitalization of
      $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the most recent index
      selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Future infrastructure spending in the US',
        year: 2015,
        link:
          'http://www.pwc.com/us/en/capital-projects-infrastructure/assets/cpi-us-outlook-2025.pdf',
      },
    ],
  },
  {
    name: 'Advanced Transport Systems',
    symbolGtr: 'KATS',
    liveDate: new Date(2016, 11, 9),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/katsp.jpg'),
    symbol: 'KATSP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-advanced-transport-systems-index-usd',
    description: `
      Today, our vehicles are increasingly connected via centralized logistics platforms;
      nonetheless, there are many companies focused on producing even more advanced connective
      technologies that will improve efficiency for millions of cars, buses, and trucks. These
      connected capabilities will allow the operators of vehicle fleets to optimize and manage their
      transportation assets far more effectively. These advanced technologies include features like
      vehicle tracking, route optimization, vehicle diagnostics, driver behavior, security and
      central control. These systems are also proliferating as a cornerstone technology of
      car-sharing companies. Overall, the market size of companies building these systems is
      predicted to grow to nearly $28bn by 2021[1], making fleets of the future smarter and more
      interconnected than ever before.
    `,
    rules: `
      The S&P Kensho Advanced Transport Systems℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in optimizing the efficiency of managing large fleets
      of vehicles, cargo transportation, and mass transit, and is composed of US-listed companies
      focused on related activities as a principal component of their business strategy as well as
      the supply-chain companies involved in the industry. Only those companies with a market
      capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the
      most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Fleet Management Systems Market - Global Forecast to 2021',
        year: 2016,
        link:
          'http://www.marketsandmarkets.com/Market-Reports/fleet-management-systems-market-1020.html',
      },
    ],
  },
  {
    name: 'Global Space',
    symbolGtr: 'KMARSG',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.GLOBAL,
    image: require('./assets/images/kmarsgp.jpg'),
    symbol: 'KMARSGP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-global-space-index-usd',
    description: `
      The so-called "Space Race" between the United States and the former Soviet Union during the
      Cold War was a boon for technological development and industry. Today, the space industry is
      driven by desire for commercialization and scientific exploration of the final frontier.
      Rising interest in space tourism, a growing demand for rare-earth minerals and even
      interplanetary colonization is propelling companies to expand the scope and availability of
      space travel and exploration into deep space. This is prompting demand for rockets, launch
      vehicles, satellites, and their subsystems, which together make Space a $330bn global
      industry.[1] NASA is predicting it will be able to send humans to an asteroid by 2025 and Mars
      in the 2030's.[2]
    `,
    rules: `
      The S&P Kensho Global Space℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the Space sector, composed of internationally listed companies
      focused on space-related activities as a principal component of their business strategy as
      well as the supply-chain companies involved in the industry. Only those companies with a
      market capitalization of $300mm and a 3 month average daily value traded (ADVT) of $2mm as of
      the most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Space Foundation: The Space Report',
        year: 2015,
        link:
          'https://www.spacefoundation.org/sites/default/files/downloads/The_Space_Report_2015_Overview_TOC_Exhibits.pdf',
      },
      {
        name: "NASA's Journey to Mars",
        year: 2014,
        link: 'http://www.nasa.gov/content/nasas-journey-to-mars',
      },
    ],
  },
  {
    name: 'Global Drones',
    symbolGtr: 'KDRONEG',
    liveDate: new Date(2016, 5, 27),
    indexType: INDEX_TYPES.GLOBAL,
    image: require('./assets/images/kdronegp.jpg'),
    symbol: 'KDRONEGP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-global-drones-index-usd',
    description: `
      Drones were initially popularized through military applications, but their most prevalent use
      in the New Economy will likely be recreational and commercial: the total global drone market
      is expected to exceed $100bn by 2020.[1] While a significant portion of the market for drones
      will come from hobbyist users, the increasing payload capacity and ranges of non-military
      drones means that commercial use will become increasingly widespread - for aerial imaging,
      repairs, shipments, and even enhanced provision of mobile data coverage. The companies
      involved in drone production encompass a wide range of players, from large conglomerates, to
      young specialists that make specialized components or complete drones.
    `,
    rules: `
      The S&P Kensho Global Drones℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the drone industry, and is composed of internationally listed
      companies focused on drone-related activities as a principal component of their business
      strategy as well as the supply-chain companies involved in the industry. Only those companies
      with a market capitalization of $100mm and a 3 month average daily value traded (ADVT) of
      $1mm as of the most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Global Drone Spending To Exceed $100 Billion In Five Years, Says Goldman',
        year: 2016,
        link:
          'http://www.benzinga.com/analyst-ratings/analyst-color/16/03/7705103/global-drone-spending-to-exceed-100-billion-in-five-year',
      },
    ],
  },
  {
    name: 'New Economies Composite',
    symbolGtr: 'KNEX',
    liveDate: new Date(2017, 1, 6),
    indexType: INDEX_TYPES.COMPOSITE,
    image: require('./assets/images/knexp.jpg'),
    symbol: 'KNEXP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-new-economies-composite-index-usd',
    description: `
      The S&P Kensho New Economies Composite℠ Index comprehensively captures the 21st Century
      Sectors that are propelling the Fourth Industrial Revolution and fostering new industries that
      will transform every facet of our lives. The Composite Index utilizes a proprietary
      quantitative weighting algorithm to track the individual New Economy Subsector Indices in
      their lifecycle from nascent industries all the way through to maturity. It strikes a unique
      balance between mainstream and cutting-edge companies as they shift their strategic focus to
      the 21st Century Sector technologies driving the rise of the New Economy.
    `,
    rules: `
      The S&P Kensho New Economies Composite℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the New Economies 21st Century Sectors℠, and is
      comprised of a dynamically adjusted list of companies drawn from all of the Subsector
      Indices.
    `,
    sources: [],
  },
  {
    name: 'New Economies Select',
    symbolGtr: 'KNESLX',
    liveDate: new Date(2017, 11, 28),
    indexType: INDEX_TYPES.COMPOSITE,
    image: require('./assets/images/kneslxp.jpg'),
    symbol: 'KNESLXP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-new-economies-select-index-usd',
    description: `
      The S&P Kensho New Economies Select℠ Index captures the companies that are propelling the
      Fourth Industrial Revolution and fostering new industries that will transform every facet of
      our lives. The Index provides broad exposure to the New Economies via a subset of the five
      best-performing Subsector Indices. It utilizes a proprietary quantitative weighting algorithm
      to track these individual Indices in their lifecycle from nascent industries all the way
      through to maturity, striking a unique balance between mainstream and cutting-edge companies
      as they shift their strategic focus to the 21st Century Sector technologies driving the rise
      of the New Economy.
    `,
    rules: `
      The S&P Kensho New Economies Select℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the New Economies 21st Century Sectors℠, and is
      comprised of a dynamically adjusted list of companies drawn from all of the Subsector
      Indices.
    `,
    sources: [],
  },
  {
    name: 'Smart Borders',
    symbolGtr: 'KDMZ',
    liveDate: new Date(2017, 2, 16),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kdmzp.jpg'),
    symbol: 'KDMZP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-smart-borders-index-usd',
    description: `
      Governments have long used border security as a means to maintaining their respective nation’s
      well-being and integrity. However, the medieval moat that once guarded the castle is certainly
      no longer the answer to contemporary border issues, given how rapid pace of globalization has
      broken down national barriers (both physical and metaphorical) over the past few decades. And
      to keep pace with these changes, security-related companies have made significant progress in
      technological innovation. These companies work closely with public and private groups to
      ensure the health of a nation.
    `,
    rules: `
      The S&P Kensho Smart Borders℠ Index uses an entirely rules-based methodology to objectively
      uncover companies involved in the advanced border security, and is composed of
      US-listed companies focused on these activities as a principal component of their business
      strategy as well as the supply-chain companies involved in the industry. Only those companies
      with a market capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm
      as of the most recent index selection date are eligible to be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Digital Communities',
    symbolGtr: 'KSOCIAL',
    liveDate: new Date(2018, 9, 1),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/ksocialp.jpg'),
    symbol: 'KSOCIALP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-digital-communities-index-usd',
    description: `
      Online, or digital, communities have become such a dominant form of communication and
      information sharing, it is hard to recall how it all worked before they first appeared.
      Whether it is for meeting new people, connecting with friends and family, maintaining
      professional connections, or even specialized gaming communities, there is something for
      everyone. The first social media site was a website called Six Degrees, a play on six degrees
      of separation, which operated from 1997 to 2001, before MySpace and subsequently Facebook
      dominated the segment. Facebook alone claims to now have over 2 billion users worldwide as of
      Q2 2018. By 2019, it is estimated that global social media ad revenues will have grown by over
      270% in the 6 years since 2013[1], and in 2018, is worth an impressive $51B[2]. Even with 70%
      of the populus in North America using social media, there is still room to grow both in the US
      and the globally where penetration is at 42%[2].
    `,
    rules: `
      The S&P Kensho Digital Communities℠ Index uses an entirely rules-based methodology to
      objectively identify companies involved in digital social networking services, such as
      platforms connecting a user’s profile with another individual or group, allowing users to
      communicate and view user generated content, as well as online gaming applications with a
      focus on community. Only those companies with a market capitalization of $100mm and a 3 month
      average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Global revenue from social media from 2013 to 2019',
        year: 2018,
        link: 'https://www.statista.com/statistics/562397/worldwide-revenue-from-social-media/',
      },
      {
        name: 'Social Media Advertising Stats that Matter to Marketers in 2018',
        year: 2018,
        link: 'https://blog.hootsuite.com/social-media-advertising-stats/',
      },
    ],
  },
  {
    name: 'Enterprise Collaboration',
    symbolGtr: 'KTEAM',
    liveDate: new Date(2018, 9, 29),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kteamp.jpg'),
    symbol: 'KTEAMP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-enterprise-collaboration-index-usd',
    description: `
      The consumerization of IT, or the adoption of consumer-oriented capabilities by enterprises,
      isn’t a new thing. In fact, we saw this trend way back in the 1990s when corporate portals
      were born out of the pioneering consumer portals hosted by the likes of Yahoo and AOL. Now,
      almost all enterprises, large and small, use some type of collaboration tool. This ranges from
      full-fledged application suites, like Microsoft’s Office, to more lightweight workflow or
      messaging tools, such as Slack or Atlassian’s HipChat and JIRA. However, the biggest way in
      which this industry is differentiating itself from the past years is through its focus on
      extensive integration. The proliferation of modular architecture and API-focused development
      allows an increasingly diverse set of platforms to coordinate and communicate with each other,
      which allows workplace users to accelerate and streamline collaboration. And it’s not just
      through licensed applications - collaboration platforms as a service (CPAAS) enable businesses
      to rapidly and smoothly add real-time communication features (such as voice, video, or
      messaging) to their own applications and websites by handling a large portion of the backend
      infrastructure builds and providing a simple plug-and-play experience for companies.

      The benefits of these capabilities to the current and future workforce are broad. In 2017,
      over 3.7 million employees worked remotely at least half of their time, and the number of
      telecommuting employees has grown 115% since 2005[1]. As more employees fulfill
      responsibilities away from the physical location of their offices, they will need these
      technologies to ensure that they remain in seamless communication with their co-workers.
    `,
    rules: `
      The S&P Kensho Enterprise Collaboration℠ Index uses an entirely rules-based methodology to
      objectively identify companies involved in enterprise collaboration services, such framework
      collaboration platforms and cloud communication platforms / communications platform as a
      service (CPaaS). Only those companies with a market capitalization of $100mm and a 3 month
      average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: '11 Surprising Working From Home Statistics',
        year: 2018,
        link: 'https://www.fundera.com/resources/working-from-home-statistics#footnote',
      },
    ],
  },
  {
    name: 'Future Communication',
    symbolGtr: 'KCONNECT',
    liveDate: new Date(2018, 9, 29),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    image: require('./assets/images/kcnnectp.jpg'),
    symbol: 'KCNNECTP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-future-communication-index-usd',
    description: `
      The mechanisms that we use to communicate with one another, and the efficacy of them, have
      changed more in the past 20 years than at any other time. There have, of course, been
      momentous developments in human history: the first recorded handwritten letter was composed by
      the Persian queen Atossa around 500 BC and the first stamped letter was sent in 1840[1],
      shortly before the telephone was invented by Alexander Graham Bell in 1876. Digital
      communications, however, have radically altered the extent to which people can make their
      voices heard and make connections around the world. Since the first email was sent in 1971[2],
      the advent of the public Internet in the mid-1990s changed the game entirely. The introduction
      of social media, both for social and professional connections, has fundamentally altered the
      way we connect and share information. This has extended to the workplace where collaborative
      frameworks have introduced not only the technical capabilities to include voice, video, and
      data sharing into any application, but also the spirit of the social experience. Virtual and
      augmented reality may hail an era where physical contact is replaced by a fully immersive
      virtual experience. It’s big business too - with 42% of the world’s population already using
      social media and generating over $50B in revenues annually in that segment alone[3].
    `,
    rules: `
      The S&P Kensho Future Communication℠ Index uses an entirely rules-based methodology to
      objectively identify companies involved in the Future Communications 21st Century Sector℠,
      and is comprised of those companies in the S&P Kensho Digital Communities℠ Index (KSOCIALP),
      the companies in the S&P Kensho Enterprise Collaboration℠ Index (KTEAMP), and the companies
      in the S&P Kensho Virtual Reality℠ Index (KVRP) that are not exclusively involved in military
      or medical applications. Only those companies with a market capitalization of $100mm and a 3
      month average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: 'History of handwritten letters',
        year: 2000,
        link: 'https://handwrittenletters.com/history_of_handwritten_letters.html',
      },
      {
        name: 'Email timeline',
        year: 2002,
        link: 'https://www.theguardian.com/technology/2002/mar/13/internetnews',
      },
      {
        name: 'Social Media Advertising Stats that Matter to Marketers in 2018',
        year: 2018,
        link: 'https://blog.hootsuite.com/social-media-advertising-stats/',
      },
    ],
  },
  {
    name: 'Electric Vehicles',
    symbolGtr: 'KEV',
    liveDate: new Date(2018, 8, 17),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kevp.jpg'),
    symbol: 'KEVP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-electric-vehicles-index-usd',
    description: `
      In an age of global warming and dangerous levels pollution in some of the most densely
      populated cities in the work, the desire for zero emissions transportation becomes ever more
      apparent. By 2016, there were over 268 million motor vehicles on the roads in the US[1] and
      over 1.2 billion worldwide. The Environmental Protection Agency (EPA) in the US estimates
      that vehicles account for 75% of the country’s carbon monoxide emissions[2], and 20% of all
      emissions[3]. Those are staggering numbers but significant technology and materials
      advancements over the past few years, together with government incentives, have ushered in the
      very real and practical possibility of clean, zero emissions travel.
    `,
    rules: `
      The S&P Kensho Electric Vehicles℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the electric vehicle sector and the ecosystems
      supporting it, and is composed of US-listed companies focused on producing electric road
      vehicles and associated subsystems, powertrains, energy storage systems, clean fuel
      technology, such as as hydrogen fuel cells, and charging infrastructure. Only those companies
      with a market capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm
      as of the most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Number of cars in the U.S. 1990-2016',
        year: 2018,
        link:
          'https://www.statista.com/statistics/183505/number-of-vehicles-in-the-united-states-since-1990/',
      },
      {
        name: 'Carbon Monoxide Concentrations',
        year: 2017,
        link: 'https://cfpub.epa.gov/roe/indicator_pdf.cfm?i=4',
      },
      {
        name: 'Car Emissions and Global Warming',
        year: 2018,
        link: 'https://www.ucsusa.org/clean-vehicles/car-emissions-and-global-warming#.W4BlVuhKj-i',
      },
    ],
  },
  {
    name: 'Democratized Banking',
    symbolGtr: 'KFIN',
    liveDate: new Date(2018, 8, 4),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    image: require('./assets/images/kfinp.jpg'),
    symbol: 'KFINP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-democratized-banking-index-usd',
    description: `
      The financial crisis prompted an unprecedented, coordinated central bank response to stabilize
      the financial system and forced financial institutions to bolster their balance sheets, shrink
      product portfolios, and adjust business strategies and operating models. While changes remain
      in-flight, financial institutions are generally healthier than they had been pre-crisis.
      Despite this upheaval, even in the face of increased regulatory oversight, increasing
      technical capabilities, and compressed margins, the shape of our financial system, and the
      participants in it, has not changed that much. However, alternatives to the established
      paradigms are now emerging, powered by exponential progress in artificial intelligence, the
      abundance of readily accessible compute power, and a cultural shift by consumers demanding
      more direct determination of their financial outcomes. Robo-advisors, peer-to-peer lending and
      crowdfunding platforms, together with distributed ledger and digital payment capabilities are
      disrupting the traditional Financial Services playbook. Established companies have taken note
      and a number are either jumping in to compete directly or partnering with these upstart firms.
      What is equally clear is that this is just the beginning and that some of the most inefficient
      practices in the financial system are directly in the line of fire. Democratized Banking, the
      next generation of our financial system, is underway.
    `,
    rules: `
      The S&P Kensho Democratized Banking℠ Index uses an entirely rules-based methodology to
      objectively uncover companies involved in the Democratized Banking 21st Century Sector℠,
      and is comprised of those companies in the S&P Kensho Alternative Finance℠ Index (KALTFINP),
      the S&P Kensho Future Payments℠ Index (KPAYP), and the financial components of the S&P Kensho
      Distributed Ledger℠ Index (KLEDGERP). Only those companies with a market capitalization of
      $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the most recent index
      selection date are eligible to be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Alternative Finance',
    symbolGtr: 'KALTFIN',
    liveDate: new Date(2018, 8, 24),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kaltfinp.jpg'),
    symbol: 'KALTFINP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-alternative-finance-index-usd',
    description: `
      Ten or fifteen years ago, you had relatively few options if you wanted to raise investments in
      a project, get a loan, or to get help managing your money, particularly if you weren’t
      raising, or worth, millions: you went to your bank, maybe family and friends, or your friendly
      financial advisor (often affiliated with your bank). However, the landscape has changed
      dramatically over the past few years: the advent of crowdfunding and peer-to-peer lending has
      started to democratize the financial services landscape. Robo-advisors have also helped to
      promote lower cost wealth management and to make it more accessible to the millennial
      generation… and they haven’t really even got started yet. What is not in any doubt, is that
      these emerging capabilities have shaken up the industry and promise to do a lot more in the
      future. The big players are taking notice and it is the consumer and small business that will
      likely benefit the most.
    `,
    rules: `
      The S&P Kensho Alternative Finance Index℠ uses an entirely rules-based methodology to
      objectively identify companies involved in  building out the next generation capabilities
      within financial services, including direct lending, crowdfunding, automated wealth
      management, usage or on-demand insurance services, and digital currencies and related
      capabilities.  Only those companies with a market capitalization of $100mm and a 3 month
      average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [],
  },
  {
    name: 'Future Payments',
    symbolGtr: 'KPAY',
    liveDate: new Date(2018, 8, 24),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kpayp.jpg'),
    symbol: 'KPAYP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-future-payments-index-usd',
    description: `
      The value of global payments transactions reached USD 420 trillion in 2016 according to the
      Boston Consulting Group[1], representing over USD 800 billion in revenue for financial
      institutions worldwide. Big numbers indeed. Up until recently, this was an exclusive ecosystem
      with major banks capturing the vast majority of this business - from some perspectives, for
      good reason: they have well-established expertise, sizable balance sheets, and stability. But,
      as is common amongst closed systems, there had been a lack of innovation for many years and
      the underlying infrastructure remains very inefficient. However, the emergence of digital
      tools and technologies, together with increasing regulatory intervention to open up the
      payments market, has presented significant opportunities to challenge the incumbent banks.
      Mobile device manufacturers such as Apple and Samsung, technology firms such as Google and
      Facebook, retailers such as Amazon and Alibaba, and thousands of other fintech companies are
      positioning themselves to disrupt this space and take a piece of the action. Capgemini and BNP
      Paribas in their 2017 World Payments Report[2] suggest that electronic and mobile payments
      will account for 50% of all credit card transactions by 2019 and take a 32% share of the
      global non-cash transaction market by 2020.
    `,
    rules: `
      The S&P Kensho Future Payments℠ Index uses an entirely rules-based methodology to objectively
      identify companies involved in building the next generation of payment capabilities, and is
      composed of US-listed companies providing products or services related to general-purpose
      platforms that allow consumers to transact using a digital balance, such as digital wallets,
      platforms that allow merchants to manage multi-channel payments in one system, real-time
      payments and transfers across consumer and merchant accounts, transaction security (i.e.
      tokenization, end-to-end encryption), and biometrically-enabled payments. Only those companies
      with a market capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm
      as of the most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'BCG Global Payments 2017 Report',
        year: 2017,
        link: 'http://image-src.bcg.com/Images/BCG-Global-Payments-2017-Oct-2017_tcm108-173047.pdf',
      },
      {
        name: 'Capgemini & BNP Paribas 2017 World Payments Report',
        year: 2017,
        link: 'https://www.worldpaymentsreport.com/download',
      },
    ],
  },
  {
    name: 'Distributed Ledger',
    symbolGtr: 'KLEDGER',
    liveDate: new Date(2018, 8, 24),
    indexType: INDEX_TYPES.SUBINDEX,
    image: require('./assets/images/kledgerp.jpg'),
    symbol: 'KLEDGERP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-distributed-ledger-index-usd',
    description: `
      Cryptocurrencies may hog the headlines but it is perhaps the underpinning technology that may
      well disrupt the world of finance first. Distributed ledger technology refers to a novel and
      fast-evolving approach to recording and sharing data across multiple data stores (or ledgers),
      across a distributed network of participants. In 2008, a landmark paper written by an as yet
      unidentified person using the pseudonym Satoshi Nakamoto, "Bitcoin: A Peer-to-Peer Electronic
      Cash System", proposed a novel approach of transferring "funds" in the form of "Bitcoin" in a
      P2P manner[1]. This paper formed the basis for Distributed Ledger and the associated
      Blockchain implementation that underpins Bitcoin. However, the wider (and anticipated to be
      most impactful) use of it extends far beyond this limited application, revolutionizing how
      securities transactions are recorded and settled, how payments are conducted, and even how
      medical records are maintained and made available to authorized participants. The removal of a
      centralized ledger holds the possibility of streamlining many of these activities, removing
      multiple intermediaries and steps from each process, thus improving both speed and efficiency.
    `,
    rules: `
      The S&P Kensho Distributed Ledger℠ Index uses an entirely rules-based methodology to
      objectively identify companies involved in the advancement of distributed ledger technology
      and those enabling services, such as miners.  Only those companies with a market
      capitalization of $100mm and a 3 month average daily value traded (ADVT) of $1mm as of the
      most recent index selection date are eligible to be included in the index.
    `,
    sources: [
      {
        name: 'Distributed Ledger Technology (DLT) and Blockchain',
        year: 2017,
        link:
          'http://documents.worldbank.org/curated/en/177911513714062215/pdf/122140-WP-PUBLIC-Distributed-Ledger-Technology-and-Blockchain-Fintech-Notes.pdf',
      },
    ],
  },
  {
    name: 'Human Evolution',
    symbolGtr: 'KEVOLVE',
    liveDate: new Date(2018, 5, 22),
    indexType: INDEX_TYPES.SECTOR_AGGREGATE,
    image: require('./assets/images/kevolvep.jpg'),
    symbol: 'KEVOLVEP',
    ccy: 'USD',
    spLink: 'https://spindices.com/indices/equity/sp-kensho-human-evolution-index-usd',
    description: `
      While the point of Singularity - the convergence of man and machine, when our intelligence and
      physical being will become increasingly non-biological - may still be some way off,
      developments in human enhancement technology mean that we are getting ever closer. From
      fundamentally altering our genetic makeup to advanced wearable augmented reality (AR) devices
      that augment sensory capabilities and implantable medical devices that can directly affect an
      individual's physical condition, the human evolution industry is at the cutting edge of
      technological research and development. Investment also continues to pour into this space with
      nearly $10B of venture capital funding in the broader biotechnology industry in 2017, with
      $1.5B invested in genetics alone[1]. The possibility for vastly longer, healthier, and more
      productive lives is within our grasp.
    `,
    rules: `
      The S&P Kensho Human Evolution℠ Index uses an entirely rules-based methodology to objectively
      identify companies involved in the Human Evolution 21st Century Sector℠, and is comprised of
      those companies in the S&P Kensho Genetic Engineering℠ Index (KDNAP), the companies producing
      human-capability enhancement products or services in S&P Kensho Wearables℠ Index (KBORGP) and
      S&P Kensho Virtual Reality℠ Index (KVRP), the medical surgical, nano- and microbots
      components from the S&P Kensho Nanotechnology℠ Index (KNANOP) and the S&P Kensho Robitics℠
      Index (KBOTSP), and 3D printing technologies for medical purposes from the S&P Kensho 3D
      Printing℠ Index (KDDDPP). Only those companies with a market capitalization of $100mm and a 3
      month average daily value traded (ADVT) of $1mm as of the most recent index selection date are
      eligible to be included in the index.
    `,
    sources: [
      {
        name: '2017 is a record year for VC funding in biotech, genetics',
        year: 2017,
        link:
          'https://pitchbook.com/news/articles/2017-is-a-record-year-for-vc-funding-in-biotech-genetics',
      },
    ],
  },
]

export const PRESS_VIDEOS = [
  {
    title: 'Top Performers of Autonomous Vehicles Index',
    source: 'CNBC',
    link:
      'https://www.cnbc.com/video/2017/09/18/top-performers-of-autonomous-vehicle-index.html?play=1',
    date: new Date(2017, 8, 18),
  },
  {
    title: 'Virtual Reality Index on the Rise',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000624855&play=1',
    date: new Date(2017, 5, 6),
  },
  {
    title: 'Russian and Chinese Cyber Threats are Making this Index Soar',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000584937&play=1',
    date: new Date(2017, 0, 17),
  },
  {
    title: 'Kensho Space Index Rockets 30%',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000608045&play=1',
    date: new Date(2017, 3, 4),
  },
  {
    title: 'Top Performers of the Kensho Robotics Index',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000609314&play=1',
    date: new Date(2017, 3, 11),
  },
  {
    title: 'Kensho Autonomous Vehicle Index Up 46%',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000622201&play=1',
    date: new Date(2017, 4, 26),
  },
  {
    title: 'Kensho Autonomous Vehicles Index Soars',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000606476&play=1',
    date: new Date(2017, 2, 31),
  },
  {
    title: "Soaring Performance of Kensho's New Economy Indices",
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000593521',
    date: new Date(2017, 1, 15),
  },
  {
    title: 'Kensho Drones Index Soars',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000591194',
    date: new Date(2017, 1, 7),
  },
  {
    title: 'Kensho Wearables Index up 25%',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000586596',
    date: new Date(2017, 0, 23),
  },
  {
    title: 'Kensho Space Index Continues to Soar',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000586593',
    date: new Date(2017, 0, 19),
  },
  {
    title: 'Breaking Down the Top Components of the Kensho Smart Buildings Index',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000585344',
    date: new Date(2017, 0, 18),
  },
  {
    title: 'Russian and Chinese Cyber Threats are Making this Index Soar',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000584937',
    date: new Date(2017, 0, 17),
  },
  {
    title: 'Trump Trade: Smart Grid Stocks',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000579286',
    date: new Date(2016, 11, 27),
  },
  {
    title: 'Kensho Drones Index',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000558074',
    date: new Date(2016, 9, 10),
  },
  {
    title: 'Kensho VR Index',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000558069',
    date: new Date(2016, 9, 10),
  },
  {
    title: 'Introducing the Kensho New Economy Indices',
    source: 'CNBC',
    link: 'http://video.cnbc.com/gallery/?video=3000541344',
    date: new Date(2016, 7, 8),
  },
]

export const WHITEPAPERS = [
  {
    title:
      'Innovation Patterns: Upgrading Sectoral Classification for the Fourth Industrial Revolution',
    source: 'SSRN',
    fullLink: 'https://papers.ssrn.com/sol3/papers.cfm?abstract_id=2928335',
    link: require('./assets/whitepapers/innovation-patterns-ssrn.pdf'),
    date: new Date(2017, 2, 6),
  },
]
