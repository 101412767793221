import cx from 'classnames'
import * as React from 'react'
import {Link} from 'react-router-dom'

import logo from '../../assets/images/logo.png'
import {navigation} from '../../constants'

import {renderDesktopNavItem} from './utils'
import cs from './styles.css'

export default function DesktopHeader() {
  return (
    <header className={cx(cs.header, cs.desktopHeader)}>
      <Link to="/">
        <div className={cs.logoContainer}>
          {/* TODO (katy): change the logo from png to svg file */}
          <img src={logo} alt="Kensho" className={cs.logo} />
        </div>
      </Link>
      <nav className={cs.links}>{navigation.map(renderDesktopNavItem)}</nav>
    </header>
  )
}
