import * as React from 'react'
import {Link} from 'react-router-dom'
import cx from 'classnames'

import ExternalLink from '../ExternalLink'

import DropdownMenu from './DropdownMenu'
import cs from './styles.css'

function renderNavLink(link, isTopLevel) {
  const classes = cx(cs.link, {
    [cs.navItem]: isTopLevel,
    [cs.disabled]: !link.to,
  })

  if (!link.to)
    return (
      <div className={classes} key={link.label}>
        {link.label}
      </div>
    )

  if (link.newTab) {
    return (
      <ExternalLink to={link.to} key={link.label} className={classes}>
        {link.label}
      </ExternalLink>
    )
  }

  return link.isExternal ? (
    <a href={link.to} className={classes} key={link.label}>
      {link.label}
    </a>
  ) : (
    <Link to={link.to} className={classes} key={link.label}>
      {link.label}
    </Link>
  )
}

const renderMobileNavGroup = group => (
  <div className={cs.topLevel} key={group.link.label}>
    {renderNavLink(group.link, true /* isTopLevel */)}
    <div className={cs.sublinks}>
      {group.sublinks.map(link => renderNavLink(link, false /* isTopLevel */))}
    </div>
  </div>
)

export const renderMobileNavItem = item =>
  item.isGroup ? renderMobileNavGroup(item) : renderNavLink(item, true /* isTopLevel */)

const renderDesktopNavGroup = group => (
  <DropdownMenu
    topLink={renderNavLink(group.link)}
    key={group.link.label}
    className={cx(cs.navItem, {
      [cs.hasLink]: group.link.to,
    })}
  >
    {group.sublinks.map(link => renderNavLink(link, false /* isTopLevel */))}
  </DropdownMenu>
)

export const renderDesktopNavItem = item =>
  item.isGroup ? renderDesktopNavGroup(item) : renderNavLink(item, true /* isTopLevel */)
