import * as React from 'react'
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import {Provider} from 'react-redux'

import ErrorBoundary from './components/ErrorBoundary'
import Browse from './pages/Browse'
import DataProducts from './pages/DataProducts'
import Press from './pages/Press'
import Team from './pages/Team'
import Details from './pages/Details'
import Overview from './pages/Overview'

function renderIndexPage(router) {
  const {index} = router.match.params
  const upper = index.toUpperCase()
  return index === upper ? <Details {...router} /> : <Redirect to={`/index/${upper}`} />
}

const Disclaimers = React.lazy(() => import('./pages/Disclaimers'))

export default function App(props) {
  return (
    <ErrorBoundary>
      <Provider {...props}>
        <BrowserRouter>
          <React.Suspense fallback={null}>
            <Switch>
              <Route exact path="/" component={Overview} />
              <Route path="/browse" component={Browse} />
              <Route path="/data-products" component={DataProducts} />
              <Route path="/press" component={Press} />
              <Route path="/team" component={Team} />
              <Route path="/disclaimers" component={Disclaimers} />
              <Route path="/index/:index" render={renderIndexPage} />
              <Redirect to="/" />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  )
}
