import * as React from 'react'

import jvm from '../../assets/images/headshot-jvm.jpg'
import Page from '../../components/Page'

import Member from './Member'
import cs from './styles.css'

export default function TeamPage() {
  return (
    <Page>
      <section>
        <h5>Leadership Team</h5>
        <div className={cs.team}>
          <Member name="John van Moyland" title="Co-Head of Financial Products" image={jvm}>
            John joined Kensho in late 2014 and serves as Co-Head of the Financial Products
            division. Running a start-up within a start-up presented John and the team with some
            unique challenges to overcome in order to develop a successful product strategy along
            with the technology, processes, and partnerships necessary to support it. In addition to
            developing the New Economy framework and the associated next-generation classification
            standard, John has responsibility for strategic partnerships, product strategy, and
            overseeing client coverage. Prior to Kensho, he held a variety of senior
            technology-related roles on both the buy and sell sides, including Barclays Global
            Investors / BlackRock and Morgan Stanley, in New York, London, San Francisco, and Tokyo.
            John’s entrepreneurial spirit has been in evidence throughout his career, during which
            time he has co-founded three other companies.
          </Member>
        </div>
      </section>
    </Page>
  )
}
