import * as React from 'react'

import wheel from '../../assets/wheel/full.svg'

import sectors from './sectors'
import cs from './styles.css'

export default class NewEconomiesWheel extends React.Component {
  state = {
    highlightedSector: null,
  }

  handleMouseMove = event => {
    // computes which of 12 wedges the cursor is in
    const {left, top, height} = event.currentTarget.getBoundingClientRect()
    const center = height / 2
    const dx = event.clientX - left - center
    const dy = center - event.clientY + top
    const dist = Math.sqrt(dx * dx + dy * dy)
    const sectorIndex = (Math.floor(6 * (Math.atan2(dy, dx) / Math.PI) + 0.5) + 12) % 12
    this.setState(prevState => ({
      highlightedSector: dist > center / 3 ? sectors[sectorIndex] : prevState.highlightedSector,
    }))
  }

  handleMouseLeave = () => {
    this.setState({highlightedSector: null})
  }

  render() {
    const {highlightedSector} = this.state
    return (
      <div
        className={cs.wheel}
        onMouseLeave={this.handleMouseLeave}
        onMouseMove={this.handleMouseMove}
      >
        <img
          alt="New Economies Indices"
          src={wheel}
          style={{display: highlightedSector ? 'none' : 'block'}}
        />
        {sectors.filter(Boolean).map(sector => (
          <div
            key={sector.name}
            className={cs.sector}
            style={{display: sector === highlightedSector ? 'block' : 'none'}}
          >
            <img alt={sector.name} src={sector.image} />
            <div className={cs.text}>
              <div className={cs.top}>
                <h3>{sector.name}</h3>
                <p>{sector.description}</p>
              </div>
              <div className={cs.bottom}>
                {sector.relatedIndices &&
                  sector.relatedIndices.map(index => (
                    <div key={index.symbol}>
                      <span className={cs.hashtag}>#</span>
                      {index.symbol} - {index.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}
